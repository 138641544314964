<template>
	<div class="details">
		<div class="contains">
			<div class="con_top">
                <div class="con_top_left">
				    <img style="width:360px;height:360px" src="../../assets/images/app/001.jpg">
                </div>
				<div class="con_top_right">
					<h2>App安全合规基础版</h2>
					<p class="new_tit">基于相关法律法规、国家标准、行业标准对移动App应用进行隐私政策检测、敏感权限检测、数据采集检测、数据使用检测检测和安全漏洞扫描，快速精确识别应用安全及合规问题，帮助企业识别移动应用的个人隐私合规问题，实现App安全、合规发布，避免被通报下架风险。</p>
                    <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">￥2400起 （具体价格请在线咨询）</span></p>
                    </div>
                    <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                根据国家相关法律法规要求，采用专业App安全检测工具结合安全专家人工分析手段，全面检测App中可能存在的不合规项，针对检测到的问题提供专业的安全检测报告。
                            </p>
                        </div>
					</div>
				
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>
			</div>
		</div>
		<div class="conten_body">  
			<div class="conten_left">
				
				
            
        <!-- 为什么选择万方？万方有什么优势？ -->
        <!-- 应用场景 -->
				<div class="privacy">
					<h3>App安全合规基础版</h3>
                    <div class="scene">
                        <h4 class="scene_title">应用场景</h4>

                        <div class="scene_left">
                            <img src="@/assets/images/app/scene.png" alt="">
                        </div>
                        <div class="scene_right">
                            <ul>
                                <li>
                                   <div class="ding">●</div>
                                   <div class="li_right">
                                        <h4>App开发者及运营者合规需求：</h4>
                                        <p>提前发现未授权的权限使用和访问行为，避免违法违规风险。</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
				</div>
        <!-- 开展业务 -->
                <div class="why">
                    <h3>为什么要开展业务</h3>
                    <div class="because">
                        <ul>
                            <li>
                                自2019年开始，国家四部委联合制定《App违法违规收集使用个人信息行为认定方法》，细化了判定App违法违规使用个人信息的方法
                            </li>
                            <li>2020年工信部发布《关于开展纵深推进APP侵害用户权益专项整治行动的通知》。至今日，仍然有大量的App因未完成整改而遭到工信部通报.
                            </li>
                            <li>
                                2021年11月1日，《个人信息保护法》实施，对个人信息的收集、使用、保存以及重要互联网平台服务方的责任等提出明确的规定。
                            </li>
                            <li>
                                根据工信部公布的名单，问题App所涉的违规内容多且繁杂，相对于企业进行自查的难度高，漏检漏改风险大。
                                2021年，某知名网约车企业由于涉嫌违法违规收集使用个人信息问题被通报和要求下架整改，旗下二十余款App全部下架，给企业带来巨大的负面影响。
                            </li>

                        </ul>
                    </div>
                </div>
        <!-- 客户收益 -->
                <div class="customer">
                    <h3>客户收益</h3>
                    <div class="cust_content">
                        <div class="cust_left">
                            <img src="@/assets/images/app/01.png" alt="">
                        </div>
                        <div class="cust_right">
                            <ul>
                                <li>
                                    <div class="ding">●</div>
                                    <p>降低App因违规被监管部门通报及下架的风险</p>
                                </li>
                                <li>
                                    <div class="ding">●</div>
                                    <p>减少企业受通报或下架的影响</p>
                                </li>
                                <li>
                                    <div class="ding">●</div>
                                    <p>有利于企业树立良好形象</p>
                                </li>
                                <li>
                                    <div class="ding">●</div>
                                    <p>应用市场合规上线</p>
                                </li>
                                <li>
                                    <div class="ding">●</div>
                                    <p>高效低成本规避风险</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
        <!-- 产品详情 -->
                <div class="product">
                    <h3>产品详情</h3>
                    <div class="product_content">
                    <div class="basis">
                        <div class="basis_title">
                            <h4>评估依据</h4>
                        </div>
                        <div class="basis_content">
                            <ul>
                                <li>
                                    1.根据《个人信息保护法》进行合规性测评。
                                </li>
                                <li>
                                   2.根据TTAF App收集使用个人信息最小必要评估规范进行合规性测评（077系列标准 ）。
                                </li>
                                <li>
                                    3.根据TTAF App用户权益保护测评规范进行合规性测评（078系列标准 ）。
                                </li>
                                <li>
                                    4.根据网信办、工信部和公安部相关App规范及要求进行合规性测评
                                </li>
                                <li>
                                    5.根据银监会、教育部等行业App规范及要求进行合规性测评。
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="basis">
                        <div class="basis_title">
                            <h4>检测内容</h4>
                        </div>
                        <div class="basis_content">
                            <ul>
                                <li>
                                    1.隐私政策检测
                                </li>
                                <li>
                                   2.敏感权限检测
                                </li>
                                <li>
                                    3.数据采集检测
                                </li>
                                <li>
                                    4.数据使用检测
                                </li>
                                <li>
                                    5.漏洞扫描基础安全检测
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="basis">
                        <div class="basis_title">
                            <h4>交付成果</h4>
                        </div>
                        <div class="basis_content">
                            <ul>
                                <li>
                                   出具合规检测报告
                                </li>
                            </ul>
                           
                        </div>
                    </div>
                     <div class="basis_img">
                        <img src="@/assets/images/app/02.png" alt="">
                    </div>
                    </div>
                </div>
        <!-- 服务流程 -->
                <div class="service">
                    <h3>服务流程</h3>
                    <div class="img">
                        <img src="@/assets/images/app/04.png" alt="">
                    </div>
                </div>
        <!-- 时间周期 -->
                 <div class="time">
                    <h3>时间周期</h3>
                    <div class="time_text">
                        <p>五个工作日内（不含整改时间）</p>
                    </div>
                </div>
        <!-- 万方优势 -->
                <div class="advantage">
                    <h3>万方优势</h3>
                    <div class="advantage_content">
                       <div class="advantage_left">
                           <img src="@/assets/images/app/03.png" alt="">
                       </div>
                       <div class="advantage_right">
                           <ul>
                               <li>1.专注网络信息安全领域10年以上，熟悉监管部门对政策法规的具体落地要求。</li>
                               <li>2.具备国家网络信息安全主管部门颁发的安全认证资质</li>
                               <li>3.安全博士领衔多位获得国际和国内安全资质的安全专家为您服务。</li>
                               <li>4.作为App监管部门的技术支撑单位，对监管政策准确深入把握并能及时了解新政策要求。</li>
                           </ul>
                       </div>
                    </div>
                </div>
        <!-- 合作案例 -->
                <div class="cooperation">
                    <h3>合作案例</h3>
                    <div class="cooperation_img">
                        <img src="@/assets/images/app/LOGO1.jpg" alt="">
                    </div>
                </div>


				

			</div>
			<!-- right -->
			<div class="conten_right">
                <div class="phone">
                    <div class="phone_left">
                        <p>服务热线(微信同号)：</p>
                        <p>188 188 60088</p> 
                        <p>188 188 63388</p>
                    </div>
                    <div class="phone_right">
                        <img src="@/assets/images/app/code.png" alt="">
                    </div>
                </div>
                <div class="hot">
                    <div class="hot_title">
                        <h4>热门产品</h4>
                    </div>
                        <div class="hot_lists">
                            <ul>
                                <li >
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/app5">
                                        <img src="@/assets/images/app/006.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>移动应用App安全合规检测</p>
                                        <p>依据国家相关法律规定</p>
                                        <p>在线咨询</p>
                                    </div>
                                </li>
                                <li>
                                    <div class="hot_left">
                                        <router-link target="_blank" to="/app2">
                                        <img src="@/assets/images/app/002.jpg" alt="">
                                        </router-link>
                                    </div>
                                    <div class="hot_right">
                                        <p>App安全检测高级版</p>
                                        <p>依据国家相关法律规定</p>
                                        <p>￥19800</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                </div>
            </div>
			
		</div>
	</div>
</template>

<script type="text/javascript">
export default{

data(){
	return{
		transmitData:{
			title:"云上业务安全无忧保障计划初级套餐",
			content:"",
			price:'0',
			url:require('@/assets/images/yunsafe/222.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.conten_body{
	margin-top:10px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.privacy{
	// padding:0 35px;
	overflow:hidden;
    // text-align: center;
    h3{
        text-align: center;
        margin-top: 50px;
        font-size: 25px;
        line-height: 25px;
        color: #000000;
        font-weight: bold;
       
    }
    .scene{
        margin-top: 50px;
        background-color: #F5F5F5;
        width: 876px;
        height: 389px;
        clear: both;
        box-shadow:  10px 10px 5px #E2E2E2;
         margin-bottom: 4px;
        .scene_title{
            text-align: center;
            padding-top: 20px;
            font-size: 25px;
            line-height: 25px;
             color: #000000;
            font-weight: bold;
        }
        .scene_left{
            float: left;
            width: 300px;
            height: 300px;
            margin-left: 55px;
            margin-top: -15px;
            background-color: #F2F2F2;
            img{
                width: 100%;
                height:100%;
            }
        }
        .scene_right{
            float: right;
            margin-right: 20px;
            ul{
                width: 340px;
                height: 250px;
                display: flex;
                flex-direction: column;
               justify-content: center;
                li{
                    display: flex;
                    align-items: flex-start;
                    margin-top: 25px;
                    .ding{
                        font-size: 25px;
                    }
                    .li_right{
                        margin-left: 20px;
                      display: flex;
                      flex-direction: column;
                      align-items: flex-start;
                        h4{
                            font-size: 20px;
                            line-height: 22px;
                        }
                        p{
                            margin-top: 10px;
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }
}

.why{
    h3{
        font-size: 23px;
        font-weight: bold;
        text-align: center;
    }
    .because{
        margin-top: 50px;
        ul{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li{
                width: 400px;
                margin-bottom: 30px;
                padding: 0 10px;
                box-shadow:  5px 4px 0px #E2E2E2;
            }
            :nth-child(1){
                border: 1px solid #02A7F0;
                font-size: 16px;
                line-height: 18px;
                height: 90px;
                padding-top: 30px;
            }
            :nth-child(2){
                background-color:  #02A7F0;
                font-size: 16px;
                color: white;
                line-height: 18px;
               height: 90px;
                padding-top: 30px;
            }
            :nth-child(3){
                background-color:  #02A7F0;
                font-size: 16px;
                 color: white;
                line-height: 18px;
                height: 116px;
                padding-top: 50px;
            }
            :nth-child(4){
                border: 1px solid #02A7F0;
                font-size: 16px;
                line-height: 18px;
               height: 126px;
                padding-top: 40px;
            }
        }
    }

}

.customer{
    margin-top: 50px;
      box-shadow:  6px 5px 2px #E2E2E2;
    h3{
          font-size: 23px;
        font-weight: bold;
        text-align: center; 
    }
    .cust_content{
        display: flex;
        align-items: center;
        .cust_left{
            width: 440px;
            height: 300px;
            text-align: center;
            img{
                width: 300px;
                height: 300px;
            }
        }
        .cust_right{
            ul{
                li{
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;
                    .ding{
                        font-size: 25px;
                        font-weight: bold;
                        margin-right: 10px;
                    }
                    p{
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}

.product{
    margin-top: 50px;
    h3{
           font-size: 23px;
        font-weight: bold;
        text-align: center; 
    }
    .product_content{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;
        position: relative;
        .basis{
            width: 268px;
            height: 469px;
                box-shadow:  5px 4px 3px #E2E2E2;
            .basis_title{
                width: 100%;
                background: #BDE8FB;
                height: 50px;
                text-align: center;
                h4{
                    font-size: 20px;
                    line-height: 50px;
                    font-weight: bold;
                }
            }
            .basis_content{
                ul{
                    li{
                        margin-top: 20px;
                        font-size: 16px;
                        line-height: 18px;
                        padding: 0 20px;
                    }
                }
                
            }
        }
        .basis_img{
                    position:absolute;
                    bottom: 10px;
                    right: 70px;
                    margin: auto;
                    width: 250px;
                    height: 250px;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
    }
}

.service{
     margin-top: 50px;
      box-shadow:  5px 4px 3px #E2E2E2;
    h3{
           font-size: 23px;
        font-weight: bold;
        text-align: center; 
    }
    .img{
        margin-top: 20px;
        width: 100%;
        height: 100px;
        img{
            width: 100%;
            height:100%;
        }
    }
}

.time{
    margin-top: 50px;
      box-shadow:  5px 4px 3px #E2E2E2;
      border: 1px solid #F3F3F3;
      background: #F2F2F2;
    h3{
        font-size: 23px;
        font-weight: bold;
        text-align: center; 
        margin-top: 10px;
    }
    .time_text{
        text-align: center;
        margin-top: 20px;
        padding-bottom: 20px;
        p{
            font-size: 20px;
            line-height: 20px;
        }
    }
}

.advantage{
    margin-top: 50px;
      box-shadow:  5px 4px 3px #E2E2E2;
    h3{
        font-size: 23px;
        font-weight: bold;
        text-align: center; 
        margin-top: 10px;
    }
    .advantage_content{
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        margin-top:10px;
        padding: 50px;
        .advantage_left{
            width: 362px;
            height: 229px;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .advantage_right{
            width: 436px;
            margin:0 20px;
            ul{
                li{
                    margin-top: 20px;
                    font-size: 16px;
                    line-height: 18px;
                }
            }
        }
    }
}

.cooperation{
    margin-top: 50px;
      box-shadow:  5px 4px 3px #E2E2E2;
      background: #F2F2F2;
     height: 290px;
     padding-bottom:10px ;
    h3{
        font-size: 23px;
        font-weight: bold;
        text-align: center; 
        padding-top: 40px;
    }
    .cooperation_img{
        width: 100%;
        margin-top: 20px;
         margin-bottom: 10px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.conten_right{
	width:300px;
	background:#f4f4f4;
	float:right;
  display: block;
  min-height: 100%;
    .phone{
        width: 100%;
        height: 100px;
        background-color: #00A9F2;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .phone_left{
            p{
                font-size: 16px;
                line-height: 18px;
                color: white;
            }
        }
        .phone_right{
            width: 75px;
            height: 75px;
            img{
                width:100%;
                height: 100%;
                }
        }
    }
    .hot{
        margin-top: 30px;
        background-color: #F2F2F2;
        height: 380px;
        .hot_title{
            width: 300px;
            height: 35px;
            background: #00A9F2;
            h4{
                margin-left: 20px;
                font-size: 18px;
                line-height: 35px;
                color: white;
            }
        }
        .hot_lists{
            ul{
                li{
                    display: flex;
                    margin-top: 20px;
                    margin-bottom: 50px;
                    .hot_left{
                        width: 147px;
                        height: 116px;
                        img{
                            width:147px;
                            height:116px;
                        }
                    }
                    .hot_right{
                        width: 140px;
                        margin-left: 20px;
                        p{
                            font-size: 14px;
                            line-height: 25px;
                             white-space: nowrap;    
                            overflow: hidden;         
                            text-overflow: ellipsis;
                        }
                        :nth-child(1){
                            padding-right: 25px;
                            font-weight: bold;
                           
                        }
                        :nth-child(2){
                             padding-right: 48px;
                            font-size: 13px;
                             
                        }
                         :nth-child(3){
                            font-size: 16px;
                            color: #B20909;
                           
                        }
                    }
                }
            }
        }
    }

}

.contains{
    margin-top: 20px;
	padding:20px;
	background: #fff;
}
.con_top{
    display: flex;
	overflow:hidden;
}
.con_top_left{
	width:358px;
    margin-right: 75px;
}

.con_top_right{
	width:790px;
}
.con_top_right h2{
	font-size: 25px;
	color: #000000;
    font-weight: bold;
	line-height: 50px;
}
.new_tit{
	background-color:#00A9F2;
	line-height: 18px;
	font-size:16px;
    padding:10px 0;
	color:#fff;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:10px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #C10909;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 35px;
	font-weight: bold;
	color: #C10909;
	position: relative;
	top:3px;
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 14px;
            line-height: 16px;
        }
    }
}
.box_ti{
   margin-top:25px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_bottom{
	margin-top:10px;
	
}
.box_bottom a{
	width: 162px;
	height: 47px;
	background: #D97602;
	font-size: 20px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 47px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}
.details{
	width:1200px;
	margin:0 auto;

//**/





}


</style>







